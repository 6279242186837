// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { ReportsApi } from 'api/endpoints';
// Interfaces and types
import { IGetTimeSpentOutsideGeofenceReportRes } from 'types/timeSpentOutsideGeofenceReportTypes';
import { TQueryParams, IErrorRes } from 'types/appTypes';

const getTimeOutsideGeofenceReport = createAsyncThunk<
  IGetTimeSpentOutsideGeofenceReportRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>(
  'reports/getOutsideGeofenceReportThunk',
  async (params: TQueryParams, { rejectWithValue }) => {
    try {
      return await ReportsApi.getTimeOutsideGeofenceReport(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export { getTimeOutsideGeofenceReport };
