// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Layout as AntLayout } from 'antd';
// CustomHook
// TODO
// import { useClearCacheAndReloadAppAfterNewBuild } from 'customHooks';

interface ILayoutProps {
  className?: string;
  hasSider?: boolean;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Handling the overall layout of a page.
 *
 * @param className - Container className
 * @param hasSider - Whether contain Sider in children, don't have to assign it normally. Useful in ssr avoid style flickering
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Layout = ({ className, hasSider, style, children }: ILayoutProps) => {
  // useClearCacheAndReloadAppAfterNewBuild();

  return (
    <AntLayout className={className} hasSider={hasSider} style={style}>
      {children}
    </AntLayout>
  );
};

export default Layout;
