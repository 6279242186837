// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { getMissedCheckinsReportAsync } from './missedCheckinsReportThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { IGetMissedCheckinsReportRes } from 'types/missedCheckinsReportTypes';

interface ISliceMissedCheckinsReportState {
  missedCheckins: IGetMissedCheckinsReportRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceMissedCheckinsReportState = {
  missedCheckins: null,
  error: null,
  isLoading: false,
};

const missedCheckinsReport = createSlice({
  name: 'missedCheckinsReport',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET MISSED CHECKINS ASYNC THUNK
    builder.addCase(getMissedCheckinsReportAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getMissedCheckinsReportAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.missedCheckins = payload;
    });
    builder.addCase(getMissedCheckinsReportAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default missedCheckinsReport;
