export enum ShiftTypeEnum {
  JOBS = 'jobs',
  EMPLOYEES = 'employees',
}

export type TShiftType = ShiftTypeEnum.JOBS | ShiftTypeEnum.EMPLOYEES;

export interface IShiftParams {
  jobId: number;
  shiftId: number;
}

export interface ITimeEntryParams extends IShiftParams {
  timeEntryId: number;
}

export enum WeekDay {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export enum ShiftStatus {
  confirmed = 'confirmed',
  missedClockIn = 'missedClockIn',
  lateClockIn = 'lateClockIn',
  createdByEmployee = 'createdByEmployee',
  declined = 'declined',
  notYetNotified = 'notYetNotified',
  notified = 'notified',
  unassigned = 'unassigned',
}

export interface IShiftItem {
  id: number;
  jobId: number;
  jobName: string;
  userId: number;
  employeeName: string;
  startTime: string;
  endTime: string;
  breakTime: number;
  timeEntries: ITimeEntryItem[];
  status: ShiftStatus;
  showInApp: boolean;
  shortName: string;
  employeeId: number;
  employeeStatus: 'active' | 'inactive';
  shiftPlannedMinutes: number | null;
  totalWeekMinutes: number | null;
  shiftActualMinutes: number | null;
  actualStartTime: string | null;
  actualEndTime: string | null;
  startDate: string | null;
  jobIsVisibleToAll: boolean;
  preventClockInAfterEnd: boolean;
  preventClockInBeforeStart: boolean;
  isPastShift: boolean;
}

export interface IBreakTimeEntryItem {
  id: number | string | null;
  parentId?: number;
  type: string;
  startTime: string;
  endTime: string;
  differenceInMinutes?: number | string;
  shiftId?: number;
  userId?: number;
}

export interface ITimeEntryItem {
  id: number | string;
  type: string;
  startTime: string;
  endTime: string;
  totalBreakTime?: number | string;
  breakTimeEntries?: IBreakTimeEntryItem[];
  gpsTracksExist?: boolean;
}

//? ENDPOINT "/shifts" METHOD "GET"
// Important to add includeTotals:"true" params for correct Response
export interface IShiftTotals {
  shiftActualMinutes: number;
  shiftPlannedMinutes: number;
}

export interface TGetShiftsRes {
  items: IShiftItem[];
  totals: IShiftTotals;
}

//? ENDPOINT "/jobs/{id}/shifts" METHOD "POST"
export interface ICreateShiftBody {
  jobId: number;
  shiftDate: string;
  employeeIds?: number[];
  startTime: string | null;
  endTime: string | null;
  breakTime?: number;
  clockInMinsBeforeStart?: number;
  clockInMinsAfterEnd?: number;
  makeDuplicatesOnDays?: WeekDay[];
  numberOfClones?: number;
  copyScheduledEmployees?: boolean;
  payEmployeeOtherRate: boolean;
  payEmployeeOvertimeRate?: boolean;
  billClientAtOvertimeRate?: boolean;
  doNotAllowEmployeePickAnotherShift: boolean;
  preventClockInAfterEnd: boolean;
  preventClockInBeforeStart: boolean;
  isBillable: boolean;
  notes: string;
  isMarkedAsOpened: boolean;
}

export interface ICreateShiftRes {
  message: string;
  errors?: {
    duplicate?: string;
    copy?: string;
  };
}

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}" METHOD "GET"
export interface IGetShiftDetailsRes {
  id: number;
  jobId: number;
  jobName: string;
  userId: number;
  employeeName: string;
  startTime: string | null;
  endTime: string | null;
  startDate: string | null;
  breakTime: number | null;
  clockInMinsBeforeStart: number;
  clockInMinsAfterEnd: number;
  timeEntries: ITimeEntryItem[];
  status: ShiftStatus;
  showInApp: boolean;
  canEdit: boolean;
  canScheduleEmployee: boolean;
  canDelete: boolean;
  confirmed: boolean;
  payEmployeeOtherRate: boolean;
  payEmployeeOvertimeRate: boolean;
  billClientAtOvertimeRate: boolean;
  canAddActualHours: boolean;
  isEmployeeActive: boolean;
  doNotAllowEmployeePickAnotherShift: boolean;
  preventClockInAfterEnd: boolean;
  preventClockInBeforeStart: boolean;
  isBillable: boolean;
  notes: string;
  isMarkedAsOpened: boolean;
}

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}" METHOD "PUT"
export interface IUpdateShiftBody {
  employeeId: number | string;
  shiftDate?: string;
  startTime: string | null;
  endTime: string | null;
  breakTime?: number;
  clockInMinsBeforeStart?: number;
  clockInMinsAfterEnd?: number;
  timeEntries: ITimeEntryItem[];
  numberOfClones?: number;
  payEmployeeOtherRate: boolean;
  payEmployeeOvertimeRate: boolean;
  billClientAtOvertimeRate: boolean;
  jobId?: number;
  shiftId: number;
  doNotAllowEmployeePickAnotherShift: boolean;
  preventClockInAfterEnd: boolean;
  preventClockInBeforeStart: boolean;
  isBillable: boolean;
  notes: string;
  isMarkedAsOpened: boolean;
}

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}" METHOD "PATCH"
export type IPatchShiftParamsWithBody = {
  shiftId: number;
  jobId: number;
  userId?: number | null;
};

export interface IUpdateShiftRes {
  message: string;
  errors?: {
    duplicate?: string;
    copy?: string;
  };
}

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}" METHOD "DELETE"
export interface IDeleteShiftRes {
  message: string;
}

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}/time-entries" METHOD "POST"
export interface ICreateTimeEntryBody extends IShiftParams {
  startTime: string;
  endTime: string;
  breakTimeEntries: { startTime: string; endTime: string }[];
}

export interface ICreateTimeEntryRes {
  message: string;
}

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}/time-entries" METHOD "GET"
export type TGetTimeEntriesRes = ITimeEntryItem[];

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}/time-entries/{timeEntryId}" METHOD "GET"
export type TGetTimeEntryRes = ITimeEntryItem;

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}/time-entries/{timeEntryId}" METHOD "PATCH"
export interface IUpdateTimeEntryBody extends ITimeEntryParams {
  type: ITimeEntryItem['type'];
  startTime: ITimeEntryItem['startTime'];
  endTime: ITimeEntryItem['endTime'];
  breakTimeEntries: IBreakTimeEntryItem[];
}

export interface IUpdateTimeEntryRes {
  message: string;
}

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}/time-entries/{timeEntryId}" METHOD "DELETE"
export type IDeleteTimeEntryParams = ITimeEntryParams;

export interface IDeleteTimeEntryRes {
  message: string;
}

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}/copy" METHOD "POST"
export interface ICopyShiftBody extends IShiftParams {
  makeDuplicatesOnDays?: WeekDay[];
  copyScheduledEmployees?: boolean;
}

export interface ICopyShiftRes {
  message: string;
}

//? ENDPOINT "/shifts/copy" METHOD "POST"
export interface ICopyWeekShiftsBody {
  shiftIds: number[];
  nextWeeks: number[];
  copyScheduledEmployees: boolean;
}

export interface ICopyWeekShiftsRes {
  message: string;
}

//? ENDPOINT "/shifts/notify" METHOD "POST"
export enum EPeriodNotify {
  ALL = 'allFuture',
  CURRENT_WEEK = 'currentWeek',
  CURRENT_AND_NEXT_WEEK = 'currentNextWeek',
}
export interface INotifyEmployeesBody {
  employeeIds: number[];
  shiftPeriod: EPeriodNotify;
}

export interface INotifyEmployeesRes {
  message: string;
}

//? ENDPOINT "/shifts/" METHOD "DELETE"
export interface IDeleteShiftsBody {
  shiftsIds: number[];
}

export interface IDeleteShiftsError {
  message?: string;
  data?: { notDeletedShiftsIds: number[] };
}

//? NAMESPACE "/schedule-web" WEB SOCKET
export interface IGetShiftItemUpdateStatusWebSocket {
  id: number;
  employeeId: number;
  employeeName: string;
  confirmed: boolean;
  declined: boolean;
  showInApp: boolean;
  status: ShiftStatus.confirmed | ShiftStatus.declined;
}

export interface IGetShiftItemWithStatusWebSocket {
  status: ShiftStatus.confirmed | ShiftStatus.declined;
  shifts: IGetShiftItemUpdateStatusWebSocket[];
}

//? ENDPOINT "/shifts/" METHOD "PATCH"
export interface IUpdateEmployeesInShifts {
  userId: number | null;
  shiftIds: number[];
}

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}/time-entries/{timeEntryId}/clock-out" METHOD "POST"
export type IClockOutParams = {
  shiftId: number;
  jobId: number;
  timeEntryId: number;
};

//? ENDPOINT "/jobs/{id}/shifts/{shiftId}/time-entry-logs" METHOD "GET"
export enum EActivityType {
  ADDED = 'added',
  DELETED = 'deleted',
  UPDATED = 'updated',
}

export interface ITimeEntryLogItem {
  id: number;
  userName: string;
  activity: EActivityType;
  oldStartTime: Date;
  oldEndTime: Date;
  oldTotalBreakTime: number;
  newStartTime: Date;
  newEndTime: Date;
  newTotalBreakTime: number;
  createdAt: Date;
}

export interface ITimeEntryLogRes {
  items: ITimeEntryLogItem[];
  count: number;
}

//? ENDPOINT "/schedule-jobs" METHOD "GET"
export interface IEmptyJobItemForPeriod {
  id: number;
  name: string;
  isVisibleToAll: boolean;
  shifts: [{}];
}

export interface IEmptyJobsForPeriod {
  count: 0;
  items: IEmptyJobItemForPeriod[];
}

//? ENDPOINT "/shifts/not-notified" METHOD "GET"
export interface TGetNotNotifyShiftItem {
  id: number;
  userId: number;
  employeeName: string;
  employeeStatus: 'active' | 'inactive';
  startDate: string | null;
  showInApp: boolean;
  isPastShift: boolean;
}
export interface TGetNotNotifyShiftsRes {
  items: TGetNotNotifyShiftItem[];
}
