// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getFormsAsync,
  createFormAsync,
  getFormDetailsAsync,
  getFormResultsAsync,
  getFormResultDetailsAsync,
  updateFormAsync,
  getFormResultsAnalysisAsync,
  getScheduleReportsAsync,
  getScheduleReportDetailsAsync,
  copyFormAsync,
} from './formThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import {
  IGetFormsRes,
  IGetFormDetailsRes,
  IGetFormResultsRes,
  IGetFormResultDetailsRes,
  IGetFormResultsAnalysisRes,
  IGetScheduleReportsRes,
  IGetScheduleReportDetailsRes,
} from 'types/formTypes';
import { TSliceReducer, TActionType } from 'redux/store';

type TAction<T extends TActionType> =
  | typeof getFormsAsync[T]
  | typeof createFormAsync[T]
  | typeof getFormDetailsAsync[T]
  | typeof getFormResultsAsync[T]
  | typeof getFormResultDetailsAsync[T]
  | typeof updateFormAsync[T]
  | typeof getScheduleReportsAsync[T]
  | typeof getScheduleReportDetailsAsync[T]
  | typeof copyFormAsync[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

interface IState {
  forms: IGetFormsRes | null;
  formDetails: IGetFormDetailsRes | null;
  formResults: IGetFormResultsRes | null;
  formResultDetails: IGetFormResultDetailsRes | null;
  formResultsAnalysis: IGetFormResultsAnalysisRes | null;
  scheduleReportDetails: IGetScheduleReportDetailsRes | null;
  scheduleReport: IGetScheduleReportsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
  isPublicSignatureSaving: boolean;
  canPublicFormResultsBeSaved: boolean;
}

const initialState: IState = {
  forms: null,
  formDetails: null,
  formResults: null,
  formResultDetails: null,
  formResultsAnalysis: null,
  scheduleReport: null,
  scheduleReportDetails: null,
  error: null,
  isLoading: false,
  isPublicSignatureSaving: false,
  canPublicFormResultsBeSaved: false,
};

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    resetFormDetails: state => {
      state.formResultDetails = null;
    },
    startPublicSignatureSaving: state => {
      state.isLoading = true;
      state.isPublicSignatureSaving = true;
    },
    finishPublicSignatureSaving: state => {
      state.isLoading = false;
      state.isPublicSignatureSaving = false;
    },
    startPublicFormResultSaving: state => {
      state.canPublicFormResultsBeSaved = true;
    },
    finishPublicFormResultSaving: state => {
      state.canPublicFormResultsBeSaved = false;
    },
  },
  extraReducers: builder => {
    //* GET FORMS ASYNC THUNK
    builder.addCase(getFormsAsync.pending, handleLoadingReducer);
    builder.addCase(getFormsAsync.fulfilled, (state, { payload }) => {
      state.forms = payload;
      state.isLoading = false;
    });
    builder.addCase(getFormsAsync.rejected, handleErrorReducer);

    //* CREATE FORMS ASYNC THUNK
    builder.addCase(createFormAsync.pending, handleLoadingReducer);
    builder.addCase(createFormAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(createFormAsync.rejected, handleErrorReducer);

    //* GET FORM DETAILS ASYNC THUNK
    builder.addCase(getFormDetailsAsync.pending, handleLoadingReducer);
    builder.addCase(getFormDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.formDetails = payload;
    });
    builder.addCase(getFormDetailsAsync.rejected, handleErrorReducer);

    //* UPDATE FORM ASYNC THUNK
    builder.addCase(updateFormAsync.pending, handleLoadingReducer);
    builder.addCase(updateFormAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(updateFormAsync.rejected, handleErrorReducer);

    //* GET FORM RESULTS ASYNC THUNK
    builder.addCase(getFormResultsAsync.pending, handleLoadingReducer);
    builder.addCase(getFormResultsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.formResults = payload;
    });
    builder.addCase(getFormResultsAsync.rejected, handleErrorReducer);

    //* GET FORM RESULT DETAILS ASYNC THUNK
    builder.addCase(getFormResultDetailsAsync.pending, handleLoadingReducer);
    builder.addCase(getFormResultDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.formResultDetails = payload;
      state.formResultDetails.data.map(field => {
        return payload.data.map(newField => {
          if (newField.id === field.id) {
            return { ...newField };
          }
          return field;
        });
      });
    });
    builder.addCase(getFormResultDetailsAsync.rejected, handleErrorReducer);

    //* GET FORM RESULTS ANALYSIS ASYNC THUNK
    builder.addCase(getFormResultsAnalysisAsync.pending, handleLoadingReducer);
    builder.addCase(getFormResultsAnalysisAsync.fulfilled, (state, { payload }) => {
      state.formResultsAnalysis = payload;
      state.isLoading = false;
    });
    builder.addCase(getFormResultsAnalysisAsync.rejected, handleErrorReducer);

    //* GET SCHEDULE_REPORT RESULTS ASYNC THUNK
    builder.addCase(getScheduleReportsAsync.pending, handleLoadingReducer);
    builder.addCase(getScheduleReportsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.scheduleReport = payload;
    });
    builder.addCase(getScheduleReportsAsync.rejected, handleErrorReducer);

    //* GET SCHEDULE_REPORT DETAILS ASYNC THUNK
    builder.addCase(getScheduleReportDetailsAsync.pending, handleLoadingReducer);
    builder.addCase(getScheduleReportDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.scheduleReportDetails = payload;
    });
    builder.addCase(getScheduleReportDetailsAsync.rejected, handleErrorReducer);

    //* COPY FORM ASYNC THUNK
    builder.addCase(copyFormAsync.pending, handleLoadingReducer);
    builder.addCase(copyFormAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(copyFormAsync.rejected, handleErrorReducer);

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default formsSlice;

export const {
  resetFormDetails,
  startPublicSignatureSaving,
  finishPublicSignatureSaving,
  startPublicFormResultSaving,
  finishPublicFormResultSaving,
} = formsSlice.actions;
