// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getInvoiceAgingAsync,
  createInvoiceAgingNoteAsync,
  updateInvoiceAgingNoteAsync,
} from './invoiceAgingThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetInvoiceAgingRes, IInvoiceDetails } from 'types/invoiceAgingTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceInvoiceAgingState {
  invoiceAging: IGetInvoiceAgingRes | null;
  selectedInvoice: IInvoiceDetails | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceInvoiceAgingState = {
  invoiceAging: null,
  selectedInvoice: null,
  error: null,
  isLoading: false,
};

const invoiceAgingSlice = createSlice({
  name: 'invoiceAging',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    setInvoiceData: (state, { payload }) => {
      state.selectedInvoice = payload;
    },
  },
  extraReducers: builder => {
    //* GET INVOICE AGING ASYNC THUNK
    builder.addCase(getInvoiceAgingAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getInvoiceAgingAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoiceAging = payload;
    });
    builder.addCase(getInvoiceAgingAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE INVOICE AGING NOTE ASYNC THUNK
    builder.addCase(createInvoiceAgingNoteAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createInvoiceAgingNoteAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createInvoiceAgingNoteAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE INVOICE AGING NOTE ASYNC THUNK
    builder.addCase(updateInvoiceAgingNoteAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateInvoiceAgingNoteAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateInvoiceAgingNoteAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export const { setInvoiceData } = invoiceAgingSlice.actions;

export default invoiceAgingSlice;
