// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { InvoiceAgingApi } from 'api/endpoints';
// Interfaces and types
import {
  IGetInvoiceAgingRes,
  ICreateInvoiceAgingNoteBody,
  IUpdateInvoiceAgingBodyWithParam,
} from 'types/invoiceAgingTypes';
import { TQueryParams, IErrorRes, ICreateItemMessage, IResMessage } from 'types/appTypes';

const getInvoiceAgingAsync = createAsyncThunk<
  IGetInvoiceAgingRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('invoiceAging/getInvoiceAging', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await InvoiceAgingApi.getInvoiceAging(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createInvoiceAgingNoteAsync = createAsyncThunk<
  ICreateItemMessage,
  ICreateInvoiceAgingNoteBody,
  { rejectValue: IErrorRes }
>(
  'invoiceAging/createInvoiceAgingNote',
  async (body: ICreateInvoiceAgingNoteBody, { rejectWithValue }) => {
    try {
      return await InvoiceAgingApi.createInvoiceAgingNote(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateInvoiceAgingNoteAsync = createAsyncThunk<
  IResMessage,
  IUpdateInvoiceAgingBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'invoiceAging/updateInvoiceAgingNote',
  async ({ body, id }: IUpdateInvoiceAgingBodyWithParam, { rejectWithValue }) => {
    try {
      return await InvoiceAgingApi.updateInvoiceAgingNote({ body, id });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export { getInvoiceAgingAsync, createInvoiceAgingNoteAsync, updateInvoiceAgingNoteAsync };
