// Packages
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
// API
import { AuthAPI, CompaniesAPI } from 'api/endpoints';
// Redux
import { changeAccountName } from './authReducer';
// Modules
import webStorage from 'modules/storage';
import sessionStorageApp from 'modules/storage/sessionStorage';
import xhr from 'modules/xhr';
// Interfaces and types
import {
  ISignInBody,
  ISignInRes,
  ISignUpBody,
  ISignUpRes,
  IForgotAccountNameBody,
  IForgotAccountNameRes,
  IForgotPassBody,
  IForgotPassRes,
  IRecoverPassBody,
  IRecoverPassRes,
  ISignOutBody,
  ISignInResData,
  ISignResWhenNeedTwoFactorCode,
  ISignInWithCodeBody,
} from 'types/authTypes';
import { IErrorRes } from 'types/appTypes';
import { ICompanyAccountNameRes } from 'types/companyTypes';

const checkAccountAsync = createAsyncThunk<
  ICompanyAccountNameRes,
  string,
  { rejectValue: IErrorRes }
>('auth/checkAccount', async (accountName: string, { rejectWithValue }) => {
  try {
    const data = await CompaniesAPI.checkCompanyAccountStatus(accountName);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const signInAsync = createAsyncThunk<ISignInRes, ISignInBody, { rejectValue: IErrorRes }>(
  'auth/signIn',
  async (cred: ISignInBody, { rejectWithValue }) => {
    try {
      const data = await AuthAPI.signIn(cred);

      if ((data as ISignResWhenNeedTwoFactorCode)?.sessionToken) {
        sessionStorageApp.setData({
          accessToken: (data as ISignResWhenNeedTwoFactorCode)?.sessionToken,
        });
      }

      if ((data as ISignInResData).userId) {
        const {
          tokens,
          userId,
          hasGpsAccess,
          hasScheduleAccess,
          hasCheckpointsAccess,
          hasSubscriptionAccess,
        } = data as ISignInResData;

        webStorage.setData({
          ...tokens,
          userId,
          accountName: cred.accountName,
          hasGpsAccess,
          hasScheduleAccess,
          hasCheckpointsAccess,
          hasSubscriptionAccess,
        });
      }
      return data;
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const signInWithTwoFactorCodeAsync = createAsyncThunk<
  ISignInResData,
  ISignInWithCodeBody,
  { rejectValue: IErrorRes }
>('auth/signInWithTwoFactorCode', async (cred: ISignInWithCodeBody, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.signInWithTwoFactorCode(cred);
    const {
      tokens,
      userId,
      hasGpsAccess,
      hasScheduleAccess,
      hasCheckpointsAccess,
      hasSubscriptionAccess,
    } = data as ISignInResData;

    webStorage.setData({
      ...tokens,
      userId,
      hasGpsAccess,
      hasScheduleAccess,
      hasCheckpointsAccess,
      hasSubscriptionAccess,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const preSignUpAsync = createAsyncThunk<
  ISignUpRes,
  Partial<ISignUpBody>,
  { rejectValue: IErrorRes }
>('auth/preSignUp', async (cred: Partial<ISignUpBody>, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.signUp(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const signUpAsync = createAsyncThunk<
  ISignUpRes,
  Partial<ISignUpBody>,
  { rejectValue: IErrorRes }
>('auth/signUp', async (cred: Partial<ISignUpBody>, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.signUp(cred);

    const { tokens, role, ...restData } = data;

    webStorage.setData({
      ...tokens,
      ...restData,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const signOutAsync = createAsyncThunk<void, ISignOutBody, { rejectValue: IErrorRes }>(
  'auth/signOut',
  async (refreshToken: ISignOutBody, { rejectWithValue }) => {
    const signOut = () => {
      delete xhr.defaults.headers.common.Authorization;
      webStorage.unsetData();
      webStorage.unsetData('timeGoUse12Hours');
      sessionStorage.clear();
    };

    try {
      await AuthAPI.signOut(refreshToken);
      signOut();
    } catch (error) {
      signOut();
      rejectWithValue(error as IErrorRes);
      window?.location?.reload();
      window.location.replace('/');
    }
  },
);

const clearReducer = createAction('auth/clearReducer');

const forgotWebAddressAsync = createAsyncThunk<
  IForgotAccountNameRes,
  IForgotAccountNameBody,
  { rejectValue: IErrorRes }
>('auth/forgotWeb', async (cred: IForgotAccountNameBody, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.forgotWebAddress(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const forgotPasswordAsync = createAsyncThunk<
  IForgotPassRes,
  IForgotPassBody,
  { rejectValue: IErrorRes }
>('auth/forgotPassword', async (cred: IForgotPassBody, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.forgotPassword(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const recoverPasswordAsync = createAsyncThunk<
  IRecoverPassRes,
  IRecoverPassBody,
  { rejectValue: IErrorRes }
>('auth/recoverPassword', async (cred: IRecoverPassBody, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.recoverPassword(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const switchAccountAsync = createAsyncThunk<
  ISignInResData,
  number,
  { rejectValue: IErrorRes }
>('auth/switchAccount', async (cred: number, { rejectWithValue, dispatch }) => {
  try {
    const data = await AuthAPI.switchAccount(cred);

    const {
      tokens,
      userId,
      hasGpsAccess,
      hasScheduleAccess,
      hasCheckpointsAccess,
      hasSubscriptionAccess,
      accountName,
    } = data as ISignInResData;

    dispatch(changeAccountName(accountName));

    webStorage.unsetData('timeGo');
    sessionStorage.removeItem('timeGo');

    webStorage.setData({
      ...tokens,
      userId,
      accountName,
      hasGpsAccess,
      hasScheduleAccess,
      hasCheckpointsAccess,
      hasSubscriptionAccess,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  preSignUpAsync,
  signInAsync,
  signInWithTwoFactorCodeAsync,
  signUpAsync,
  signOutAsync,
  clearReducer,
  forgotWebAddressAsync,
  forgotPasswordAsync,
  recoverPasswordAsync,
  checkAccountAsync,
  switchAccountAsync,
};
