// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
import { store } from '../store';
// API
import { ScheduleAPI } from 'api/endpoints';
// Interfaces and types
import {
  TGetShiftsRes,
  ICreateShiftRes,
  ICreateShiftBody,
  IUpdateShiftRes,
  IUpdateShiftBody,
  IShiftParams,
  ICopyShiftBody,
  ICopyShiftRes,
  IDeleteShiftRes,
  IGetShiftDetailsRes,
  ICopyWeekShiftsBody,
  ICopyWeekShiftsRes,
  INotifyEmployeesBody,
  INotifyEmployeesRes,
  TGetTimeEntriesRes,
  IDeleteShiftsBody,
  IDeleteShiftsError,
  IEmptyJobsForPeriod,
  TGetNotNotifyShiftsRes,
} from 'types/scheduleTypes';
import { IErrorRes, TQueryParams, IResMessage } from 'types/appTypes';

const getEmptyJobsForSomePeriodAsync = createAsyncThunk<
  IEmptyJobsForPeriod,
  TQueryParams,
  { rejectValue: IErrorRes }
>('schedule/getEmptyJobs', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await ScheduleAPI.getEmptyJobsFoSomePeriod(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getShiftsAsync = createAsyncThunk<
  TGetShiftsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('schedule/getShifts', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    await store.dispatch(getEmptyJobsForSomePeriodAsync(params));

    return await ScheduleAPI.getShifts(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createShiftAsync = createAsyncThunk<
  ICreateShiftRes,
  ICreateShiftBody,
  { rejectValue: IErrorRes }
>('schedule/createShift', async (cred: ICreateShiftBody, { rejectWithValue }) => {
  try {
    return await ScheduleAPI.createShift(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getShiftDetailsAsync = createAsyncThunk<
  IGetShiftDetailsRes,
  IShiftParams,
  { rejectValue: IErrorRes }
>('schedule/getShiftDetails', async (params: IShiftParams, { rejectWithValue }) => {
  try {
    return await ScheduleAPI.getShiftDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateShiftAsync = createAsyncThunk<
  IUpdateShiftRes,
  IUpdateShiftBody,
  { rejectValue: IErrorRes }
>('schedule/updateShift', async (cred: IUpdateShiftBody, { rejectWithValue }) => {
  try {
    return await ScheduleAPI.updateShift(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteShiftAsync = createAsyncThunk<
  IDeleteShiftRes,
  IShiftParams,
  { rejectValue: IErrorRes }
>('schedule/deleteShift', async (params: IShiftParams, { rejectWithValue }) => {
  try {
    return await ScheduleAPI.deleteShift(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const copyShiftToOtherDaysAsync = createAsyncThunk<
  ICopyShiftRes,
  ICopyShiftBody,
  { rejectValue: IErrorRes }
>('schedule/copyShift', async (params: ICopyShiftBody, { rejectWithValue }) => {
  try {
    return await ScheduleAPI.copyShiftToOtherDays(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const copyWeekShiftsAsync = createAsyncThunk<
  ICopyWeekShiftsRes,
  ICopyWeekShiftsBody,
  { rejectValue: IErrorRes }
>('schedule/copyWeekShifts', async (body: ICopyWeekShiftsBody, { rejectWithValue }) => {
  try {
    return await ScheduleAPI.copyWeekShifts(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const notifyEmployeesAsync = createAsyncThunk<
  INotifyEmployeesRes,
  INotifyEmployeesBody,
  { rejectValue: IErrorRes }
>('schedule/notifyEmployees', async (body: INotifyEmployeesBody, { rejectWithValue }) => {
  try {
    return await ScheduleAPI.notifyEmployees(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getShiftsForNotifyAsync = createAsyncThunk<
  TGetNotNotifyShiftsRes,
  { dateFrom: string },
  { rejectValue: IErrorRes }
>('schedule/getShiftsForNotify', async (params: { dateFrom: string }, { rejectWithValue }) => {
  try {
    return await ScheduleAPI.getNotNotifyShifts(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getShiftTimeEntriesAsync = createAsyncThunk<
  TGetTimeEntriesRes,
  IShiftParams,
  { rejectValue: IErrorRes }
>('schedule/getShiftTimeEntries', async (params: IShiftParams, { rejectWithValue }) => {
  try {
    return await ScheduleAPI.getTimeEntries(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteShiftsAsync = createAsyncThunk<
  IResMessage,
  IDeleteShiftsBody,
  { rejectValue: IDeleteShiftsError }
>('schedule/deleteShifts', async (params: IDeleteShiftsBody, { rejectWithValue }) => {
  try {
    return await ScheduleAPI.deleteShifts(params);
  } catch (error) {
    return rejectWithValue(error as IDeleteShiftsError);
  }
});

export {
  getEmptyJobsForSomePeriodAsync,
  getShiftsAsync,
  createShiftAsync,
  getShiftDetailsAsync,
  updateShiftAsync,
  deleteShiftAsync,
  copyShiftToOtherDaysAsync,
  copyWeekShiftsAsync,
  notifyEmployeesAsync,
  getShiftsForNotifyAsync,
  getShiftTimeEntriesAsync,
  deleteShiftsAsync,
};
