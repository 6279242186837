// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { ReportsApi } from 'api/endpoints';
// Interfaces and types
import { IGetMissedCheckinsReportRes } from 'types/missedCheckinsReportTypes';
import { TQueryParams, IErrorRes } from 'types/appTypes';

const getMissedCheckinsReportAsync = createAsyncThunk<
  IGetMissedCheckinsReportRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>(
  'reports/getMissedCheckinsReportThunk',
  async (params: TQueryParams, { rejectWithValue }) => {
    try {
      return await ReportsApi.getMissedCheckinsReport(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export { getMissedCheckinsReportAsync };
