// Core
import { useEffect, useState } from 'react';
// Packages
import moment from 'moment';
// Redux
import { STORE_KEYS, useAppDispatch, useAppSelector } from 'redux/store';
import { getCurrentUserAsync } from 'redux/user/userThunk';
import { setCanSetStartEndScheduleDates } from 'redux/schedule/scheduleReducer';
// Components
import { Loader } from 'components';
// Modules
import webStorage from 'modules/storage';
// Configs
import { adminUrl } from 'configs/apiConfig';

interface TMessageData {
  userId: number;
  accountName: string;
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
}

const SignInAsAdmin = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(STORE_KEYS?.USER);

  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState<number | null>(null);

  useEffect(() => {
    const listener = ({ data, origin }: MessageEvent<TMessageData>) => {
      if (origin !== adminUrl) {
        return;
      }

      if (data && data.tokens) {
        const { tokens, ...restData } = data;

        webStorage.setData({
          ...tokens,
          ...restData,
        });

        dispatch(getCurrentUserAsync());
        setUserId(data?.userId);
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);

      setIsLoading(false);
    };
  }, [dispatch]);

  useEffect(() => {
    if (userId === user?.id) {
      moment.updateLocale(user?.language, { week: { dow: Number(user.payroll.startDay) } });

      sessionStorage.removeItem('shiftsPeriod');
      dispatch(setCanSetStartEndScheduleDates(false));
      window.location.replace(`/${user?.mainCompany.accountName}/schedule`);
    }
  }, [user, dispatch, userId]);

  return <Loader isLoading={isLoading} />;
};

export default SignInAsAdmin;
