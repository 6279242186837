// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Types
import { IResMessage, TQueryParams, ICreateItemMessage } from 'types/appTypes';
import {
  IGetInvoiceAgingRes,
  ICreateInvoiceAgingNoteBody,
  IUpdateInvoiceAgingBodyWithParam,
} from 'types/invoiceAgingTypes';

export const getInvoiceAging = (params: TQueryParams) => {
  return xhr.get<IGetInvoiceAgingRes, IGetInvoiceAgingRes>(`${ENDPOINTS.invoiceAging}`, {
    params,
  });
};

export const createInvoiceAgingNote = (body: ICreateInvoiceAgingNoteBody) => {
  return xhr.post<ICreateItemMessage, ICreateItemMessage>(
    `${ENDPOINTS.invoiceAgingNotes}`,
    body,
  );
};

export const updateInvoiceAgingNote = ({ id, body }: IUpdateInvoiceAgingBodyWithParam) => {
  return xhr.patch<IResMessage, IResMessage>(`${ENDPOINTS.invoiceAgingNotes}/${id}`, body);
};
