// Core
import { useState, useEffect } from 'react';
// Packages
import { useHistory } from 'react-router-dom';
// Components
import { Card, GridRow, GridCol, Select, FormItem, Button, Text } from 'components/lib';
import { ANCHOR_IDS } from '../SideBar';
// Custom Hooks
import { useFetch } from 'customHooks';
// ROUTES
import { ROUTES } from 'router/routes';
// Storage
import webStorage from 'modules/storage';
// Redux
import { useAppDispatch } from 'redux/store';
import { setNewSettingsAfterSwitching } from 'redux/user/userReducer';
import { getCurrentUserAsync } from 'redux/user/userThunk';
import { switchAccountAsync } from 'redux/auth/authThunk';
// API
import { AuthAPI, UserAPI } from 'api/endpoints';
// I18n
import { t } from 'i18n';
// Types
import { IGetAccountRes } from 'types/userTypes';
import { TValueType } from 'components/lib/Select';

interface IProps {
  isVerified: boolean;
  id?: number;
  onSetLoading: (flag: boolean) => void;
  onCloseModal: () => void;
}

const SwitchAccount = ({ isVerified, id, onCloseModal, onSetLoading }: IProps) => {
  const history = useHistory();

  const [accountId, setAccountId] = useState<number>();

  const dispatch = useAppDispatch();

  // Getting available employees
  const [
    { response: availableAccounts, isLoading: isLoadingAvailableAccounts },
    doFetchAvailableAccounts,
    doResetAvailableAccounts,
  ] = useFetch<IGetAccountRes, void, typeof UserAPI.getAdminAccounts>(
    UserAPI.getAdminAccounts,
  );

  useEffect(() => {
    isVerified && doFetchAvailableAccounts();

    return () => {
      doResetAvailableAccounts();
    };
  }, [isVerified, doFetchAvailableAccounts, doResetAvailableAccounts]);

  const switchAccount = async () => {
    onCloseModal();
    onSetLoading(true);

    const { refreshToken } = webStorage.getData();

    try {
      await AuthAPI.signOut({ refreshToken });
    } catch (error) {
      console.log(error);
    }

    const actionResultsSwitchAccount = await dispatch(switchAccountAsync(accountId as number));

    if (!switchAccountAsync.fulfilled.match(actionResultsSwitchAccount)) return;

    dispatch(setNewSettingsAfterSwitching(true));

    const actionResultsGetCurrentUser = await dispatch(getCurrentUserAsync());

    if (!getCurrentUserAsync.fulfilled.match(actionResultsGetCurrentUser)) return;

    try {
      const prevStorageData = webStorage.getData();
      webStorage.setData({
        ...prevStorageData,
      });

      history.replace(
        `/${actionResultsSwitchAccount?.payload?.accountName}/${ROUTES.SCHEDULE.split('/')
          .slice(2)
          .join('/')}?shouldPageBeReloaded=true`,
      );

      dispatch(setNewSettingsAfterSwitching(false));
      onSetLoading(false);
      window?.location?.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const isMultipleAccounts = availableAccounts?.items && availableAccounts?.items?.length > 1;
  const canShowSwitch = isVerified && isMultipleAccounts;

  const options = availableAccounts?.items?.filter(item => item?.id !== id);
  let emptyBlockText = '';

  if (!isVerified) {
    emptyBlockText = t('settingsModal.twoAccountsVerified');
  }

  if (isVerified && !isMultipleAccounts) {
    emptyBlockText = t('settingsModal.twoAccounts');
  }

  return (
    <Card
      id={ANCHOR_IDS.SWITCH_ACCOUNT.slice(1)}
      title={t('settingsModal.switchAccount')}
      className="infoCard"
    >
      <GridRow gutter={32}>
        <GridCol span={24}>
          {canShowSwitch && (
            <GridRow justify="start" gutter={32}>
              <GridCol span={24}>
                <FormItem label={t('settingsModal.switchCompany')}>
                  <Select
                    loading={isLoadingAvailableAccounts}
                    size="large"
                    onChange={(id: TValueType) => setAccountId(id as number)}
                  >
                    {options?.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </GridCol>

              <GridCol>
                <Button htmlType="button" type="primary" onClick={switchAccount}>
                  {t('settingsModal.switch')}
                </Button>
              </GridCol>
            </GridRow>
          )}

          {!canShowSwitch && <Text> {emptyBlockText}</Text>}
        </GridCol>
      </GridRow>
    </Card>
  );
};

export default SwitchAccount;
