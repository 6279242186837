// Interfaces and types
import { ISignInResData } from 'types/authTypes';

export interface IStorageData {
  accountName: string;
  userId: ISignInResData['userId'];
  accessToken: ISignInResData['tokens']['accessToken'];
  refreshToken: ISignInResData['tokens']['refreshToken'];
  hasGpsAccess: ISignInResData['hasGpsAccess'];
  hasScheduleAccess: ISignInResData['hasScheduleAccess'];
  hasCheckpointsAccess: ISignInResData['hasCheckpointsAccess'];
  hasSubscriptionAccess: ISignInResData['hasSubscriptionAccess'];
  quickbookCompanyId: string;
}

interface IStorage {
  getData: <T = IStorageData>(key?: string) => T;
  setData: <T = IStorageData>(data: T, key?: string) => void;
  unsetData: (key?: string) => void;
}

const storage: IStorage = {
  getData(key = 'timeGo') {
    const data = localStorage.getItem(key) || '{}';
    return JSON.parse(data);
  },

  setData(data, key = 'timeGo') {
    localStorage.setItem(key, JSON.stringify(data));
  },

  unsetData(key = 'timeGo') {
    localStorage.removeItem(key);
  },
};

export default storage;
