// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getLocationsDocsAsync,
  getLocationsDocsByLocationAsync,
  createLocationDocAsync,
  getLocationDocDetailsAsync,
  updateLocationDocAsync,
  deleteLocationDocAsync,
  getLocationsDocsByAccountNameAsync,
} from './locationsDocsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import {
  IGetLocationsDocsRes,
  IGetLocationDocDetailsRes,
  IGetLocationsDocsByLocationRes,
} from 'types/locationDocsTypes';

interface ISliceLocationsDocsState {
  locationsDocs: IGetLocationsDocsRes | IGetLocationsDocsByLocationRes | null;
  locationDocsDetails: IGetLocationDocDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceLocationsDocsState = {
  locationsDocs: null,
  locationDocsDetails: null,
  error: null,
  isLoading: false,
};

const locationsDocs = createSlice({
  name: 'locationsDocs',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET LOCATIONS DOCS ASYNC THUNK
    builder.addCase(getLocationsDocsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getLocationsDocsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.locationsDocs = payload;
    });
    builder.addCase(getLocationsDocsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET LOCATIONS DOCS BY ACCOUNT_NAME ASYNC THUNK
    builder.addCase(getLocationsDocsByAccountNameAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getLocationsDocsByAccountNameAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.locationsDocs = payload;
    });
    builder.addCase(getLocationsDocsByAccountNameAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET LOCATIONS DOCS BY LOCATION ASYNC THUNK
    builder.addCase(getLocationsDocsByLocationAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getLocationsDocsByLocationAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.locationsDocs = payload;
    });
    builder.addCase(getLocationsDocsByLocationAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE LOCATION DOC ASYNC THUNK
    builder.addCase(createLocationDocAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createLocationDocAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createLocationDocAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET LOCATION DOC DETAILS ASYNC THUNK
    builder.addCase(getLocationDocDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getLocationDocDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.locationDocsDetails = payload;
    });
    builder.addCase(getLocationDocDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE LOCATION DOC ASYNC THUNK
    builder.addCase(updateLocationDocAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateLocationDocAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateLocationDocAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE LOCATION DOC ASYNC THUNK
    builder.addCase(deleteLocationDocAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteLocationDocAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteLocationDocAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default locationsDocs;
