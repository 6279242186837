// Core
import { ReactNode, MouseEvent, CSSProperties } from 'react';
// Packages
import { Typography as AntTypography } from 'antd';
import cn from 'classnames';
// Styles
import './text.styles.scss';

interface ITextProps {
  children?: ReactNode;
  code?: boolean;
  copyable?: boolean;
  className?: string;
  deleted?: boolean;
  disabled?: boolean;
  editable?: boolean;
  ellipsis?: boolean;
  fontSize?: 'small' | 'middle' | 'large';
  id?: string;
  keyboard?: boolean;
  mark?: boolean;
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
  strong?: boolean;
  style?: CSSProperties;
  italic?: boolean;
  type?: 'secondary' | 'success' | 'warning' | 'danger';
  underline?: boolean;
  dataCy?: string;
}

/**
 * Provides multiple types of text.
 * Basic text writing, including headings, body text, lists, and more.
 *
 * @description When need to display a title or paragraph contents in Articles/Blogs/Notes.
 * When you need copyable/editable/ellipsis texts.
 *
 * @param children - Some ReactNode
 * @param code - Code style
 * @param copyable - Whether to be copyable, customize it via setting an object
 * @param className - Component className
 * @param deleted - Deleted line style
 * @param disabled - Disabled content
 * @param fontSize - Element font size
 * @param editable - 	If editable. Can control edit state when is object
 * @param ellipsis - Display ellipsis when text overflows, can configure rows and expandable by using object
 * @param id - ELement id
 * @param keyboard - Keyboard style
 * @param mark - Marked style
 * @param onClick - Set the handler to handle click event
 * @param strong - Bold style
 * @param style - To customize the styles
 * @param italic - Italic style
 * @param type - Content type
 * @param underline - Underlined style
 */

const Text = ({
  children,
  code = false,
  copyable = false,
  className,
  deleted = false,
  disabled = false,
  fontSize = 'middle',
  editable = false,
  ellipsis = false,
  id,
  keyboard = false,
  mark = false,
  onClick,
  strong = false,
  style,
  italic = false,
  type,
  underline = false,
  dataCy,
}: ITextProps) => {
  const classNames = cn(className, [fontSize], { strong });

  return (
    <AntTypography.Text
      code={code}
      copyable={copyable}
      className={classNames}
      delete={deleted}
      disabled={disabled}
      editable={editable}
      ellipsis={ellipsis}
      id={id}
      keyboard={keyboard}
      mark={mark}
      onClick={onClick}
      style={style}
      italic={italic}
      type={type}
      underline={underline}
      data-cy={dataCy}
    >
      {children}
    </AntTypography.Text>
  );
};

export default Text;
