// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getLocationsAsync,
  getLocationDetailsAsync,
  updateLocationAsync,
  deleteLocationAsync,
  createLocationAsync,
  getLocationsByAccountNameAsync,
} from './locationsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import {
  IGetLocationsRes,
  IGetLocationRes,
  IGetLocationsByAccountNameRes,
} from 'types/locationTypes';

interface ISliceLocationsState {
  locations: IGetLocationsRes | IGetLocationsByAccountNameRes | null;
  locationDetails: IGetLocationRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceLocationsState = {
  locations: null,
  locationDetails: null,
  error: null,
  isLoading: false,
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET LOCATIONS ASYNC THUNK
    builder.addCase(getLocationsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getLocationsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.locations = payload;
    });
    builder.addCase(getLocationsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET LOCATIONS (BY ACCOUNT NAME) ASYNC THUNK
    builder.addCase(getLocationsByAccountNameAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getLocationsByAccountNameAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.locations = payload;
    });
    builder.addCase(getLocationsByAccountNameAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE LOCATION ASYNC THUNK
    builder.addCase(createLocationAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createLocationAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createLocationAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET LOCATION DETAILS ASYNC THUNK
    builder.addCase(getLocationDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getLocationDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.locationDetails = payload;
    });
    builder.addCase(getLocationDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE LOCATION ASYNC THUNK
    builder.addCase(updateLocationAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateLocationAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateLocationAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE LOCATION ASYNC THUNK
    builder.addCase(deleteLocationAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteLocationAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteLocationAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default locationsSlice;
