// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { LocationsDocsAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetLocationsDocsRes,
  ICreateLocationDocRes,
  ICreateLocationDocBodyWithParam,
  IGetLocationDocDetailsParams,
  IGetLocationDocDetailsRes,
  IUpdateLocationDocDetailsParams,
  IDeleteLocationDocParams,
  IGetLocationsDocsByLocationParams,
  IGetLocationsDocsByLocationRes,
  IGetLocationsDocsByAccountNameParams,
} from 'types/locationDocsTypes';
import { TQueryParams, IErrorRes, IResMessage } from 'types/appTypes';

const getLocationsDocsAsync = createAsyncThunk<
  IGetLocationsDocsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('locationsDocs/getLocationsDocs', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await LocationsDocsAPI.getLocationsDocs(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getLocationsDocsByAccountNameAsync = createAsyncThunk<
  IGetLocationsDocsRes,
  IGetLocationsDocsByAccountNameParams,
  { rejectValue: IErrorRes }
>(
  'locationsDocs/getLocationsDocsByAccountName',
  async (params: IGetLocationsDocsByAccountNameParams, { rejectWithValue }) => {
    try {
      return await LocationsDocsAPI.getLocationsDocsByAccountName(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getLocationsDocsByLocationAsync = createAsyncThunk<
  IGetLocationsDocsByLocationRes,
  IGetLocationsDocsByLocationParams,
  { rejectValue: IErrorRes }
>(
  'locationsDocs/getLocationsDocsByLocation',
  async ({ params, locationId }: IGetLocationsDocsByLocationParams, { rejectWithValue }) => {
    try {
      return await LocationsDocsAPI.getLocationsDocsByLocation({ params, locationId });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const createLocationDocAsync = createAsyncThunk<
  ICreateLocationDocRes,
  ICreateLocationDocBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'locationsDocs/createLocationDoc',
  async (cred: ICreateLocationDocBodyWithParam, { rejectWithValue }) => {
    try {
      return await LocationsDocsAPI.createLocationDoc(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getLocationDocDetailsAsync = createAsyncThunk<
  IGetLocationDocDetailsRes,
  IGetLocationDocDetailsParams,
  { rejectValue: IErrorRes }
>(
  'locationsDocs/getLocationDocDetails',
  async (cred: IGetLocationDocDetailsParams, { rejectWithValue }) => {
    try {
      return await LocationsDocsAPI.getLocationDocDetails(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateLocationDocAsync = createAsyncThunk<
  IResMessage,
  IUpdateLocationDocDetailsParams,
  { rejectValue: IErrorRes }
>(
  'locationsDocs/updateLocationDoc',
  async (cred: IUpdateLocationDocDetailsParams, { rejectWithValue }) => {
    try {
      return await LocationsDocsAPI.updateLocationDoc(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteLocationDocAsync = createAsyncThunk<
  IResMessage,
  IDeleteLocationDocParams,
  { rejectValue: IErrorRes }
>(
  'locationsDocs/deleteLocationDoc',
  async (params: IDeleteLocationDocParams, { rejectWithValue }) => {
    try {
      return await LocationsDocsAPI.deleteLocationDoc(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getLocationsDocsAsync,
  getLocationsDocsByAccountNameAsync,
  getLocationsDocsByLocationAsync,
  createLocationDocAsync,
  getLocationDocDetailsAsync,
  updateLocationDocAsync,
  deleteLocationDocAsync,
};
